import axios from 'axios';

// ایجاد یک نمونه (instance) از Axios با تنظیمات پیش‌فرض
const apiClient = axios.create({
    baseURL: 'https://api.dkoffroad.com/v2/', // آدرس پایه‌ی API شما
    /*headers: {
        'Content-Type': 'application/json',
    },*/
    // می‌توانید تنظیمات دیگر مانند تایم‌اوت را اضافه کنید
    //timeout: 10000, // 10 ثانیه
});

// اضافه کردن Interceptors برای درخواست‌ها
/*apiClient.interceptors.request.use(
    (config) => {
        // می‌توانید توکن احراز هویت را به هدر اضافه کنید
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);*/

// اضافه کردن Interceptors برای پاسخ‌ها
/*apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // مدیریت خطاها به صورت سراسری
        if (error.response.status === 401) {
            // مثلا هدایت به صفحه لاگین
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);*/
apiClient.interceptors.request.use(
    (config) => {
        //console.log('The request was sent: ', config);
        return config;
    },
    (error) => {
        //console.error('Error in sending request: ', error);
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    (response) => {
        //console.log('The response was received: ', response);
        return response;
    },
    (error) => {
        //console.error('Error in receiving response: ', error);
        return Promise.reject(error);
    }
);
export default apiClient;
