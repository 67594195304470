import {createApp} from 'vue'
import App from './App.vue'
import '@/assets/scss/tailwind.scss'
import "@/assets/libs/@mdi/font/css/materialdesignicons.min.css"
import '@/assets/libs/tobii/css/tobii.min.css'
//import "@/assets/css/localeFont.css"
import VueEasyLightbox from 'vue-easy-lightbox'
import router from './router';
import {createPinia} from 'pinia';
import piniaPersist from 'pinia-plugin-persistedstate';

const app = createApp(App)

const pinia = createPinia();
pinia.use(piniaPersist);

app.use(VueEasyLightbox)
app.use(router);
app.use(pinia);
app.mount('#app')


    