/* eslint-disable */
import apiClient from '@/services/axios';
import router from '@/router';
import {useUserStore} from '@/stores/user.js';

// Default handleCredentialExpired
function defaultHandleCredentialExpired(errorMessage = 'Your credential is expired') {
    useUserStore().resetUser(errorMessage);
    console.log('defaultHandleCredentialExpired');
    return false;
}

// Default handleFailure
function defaultHandleFailure(errorMessage) {
    useUserStore().setErrorMessage(errorMessage);
    console.log('defaultHandleFailure');
    return false;
}

export async function useApi(
    request,
    handleSuccess,
    handleCredentialExpired = (errorMessage) => defaultHandleCredentialExpired(errorMessage),
    handleFailure = (errorMessage) => defaultHandleFailure(errorMessage)) {
    try {
        const response = await apiClient.post('', request);
        if (response.data.response === 100) {
            return handleSuccess(response);
        } else if (response.data.response === -100002) {
            handleCredentialExpired('Your credential is expired');
            await router.push({name: 'login'});
        } else {
            handleFailure(response.data.message);
        }
    } catch (err) {
        const error = err.response ? err.response.data.message : err.message;
        //console.log(error);
    } finally {
        const loading = false;
        //console.log(loading)
    }
}

export async function useUpdateApi(
    fileName,
    request,
    handleSuccess,
    handleCredentialExpired = (errorMessage) => defaultHandleCredentialExpired(errorMessage),
    handleFailure = (errorMessage) => defaultHandleFailure(errorMessage)) {
    try {
        const response = await apiClient.post('https://upload-tr.chainedminds.com/' + fileName, request);
        if (response.data.response === 100) {
            return handleSuccess(response);
        } else if (response.data.response === -100002) {
            handleCredentialExpired('Your credential is expired');
            await router.push({name: 'login'});
        } else {
            handleFailure(response.data.message);
        }
    } catch (err) {
        const error = err.response ? err.response.data.message : err.message;
        //console.log(error);
    } finally {
        const loading = false;
        //console.log(loading)
    }
}
