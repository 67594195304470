import {useUserStore} from "@/stores/user";

export default function auth({next}) {
    const userStore = useUserStore();
    const userCredential = userStore.getCredential;

    if (userCredential) {
        return next();
    } else {
        userStore.resetUser();
        return next({ name: 'Login' });
    }
}
