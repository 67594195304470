import {useApi} from '@/services/useApi';
import {defineStore} from 'pinia';
import packageJson from '../../package.json';
//import { useUserStore } from '@/stores/user.js';

//const userStore = useUserStore();
export const client = {
  appName: 'DKOffroad',
  platform: 'Web',
  market: 'Direct',
  language: 'EN',
  version: packageJson.version
};

export const useConfigStore = defineStore('config', {
  state: () => ({
    configs: {
      brands: [],
      stores: null
    }
  }),
  getters: {
    get_brands(state) {
      return state.configs.brands;
    }
  },
  actions: {
    async action_get_configs() {
      const request = {
        'request': 11024,
        'client': client
      };
      await useApi(
        request,
        (response) => {
          console.log('action_get_configs Success: ', response);
          this.set_brands(response.data.configs.brands);
        },
        (errorMessage) => {
          console.log(errorMessage)
          //userStore.resetUser(errorMessage);
        },
        (errorMessage) => {
          console.log(errorMessage)
          //userStore.setErrorMessage(errorMessage);
        }
      );
    },
    set_brands(payload) {
      this.configs.brands = payload
    }
  }
});
