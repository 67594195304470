import {createWebHistory, createRouter} from "vue-router";
import auth from "@/middleware/auth";
import middlewarePipeline from "@/middleware/middlewarePipeline";

const routes = [
  {
    path: "/",
    component: () => import("@/layout/index.vue"),
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: "",
        name: "index",
        component: () => import('@/pages/index/index.vue'),
      },
      {
        path: "home",
        name: "home",
        component: () => import('@/pages/index/home.vue'),
      },
      {
        path: "home2",
        name: "home2",
        component: () => import('@/components/navbar/home2.vue'),
      },
      {
        path: "categories",
        name: "categories",
        component: () => import('@/pages/categories/index.vue'),
        beforeEnter: (to, from, next) => {
          if (to.query.car && to.query.carID) {
            next()
          } else {
            next({name: 'cars'})
          }
        }
      },
      {
        path: 'cars',
        name: 'cars',
        component: () => import('@/pages/cars/index.vue'),
        beforeEnter: (to, from, next) => {
          if ((to.query.category && to.query.categoryID) || to.query.brand) {
            next()
          } else {
            next({name: 'categories'})
          }
        }
      },
      {
        path: 'products',
        children:[
          {
            path: '',
            name: 'product-list',
            component: () => import('@/pages/products/index.vue'),
            beforeEnter: (to, from, next) => {
              console.log(from, to)
              if (to.query.category && to.query.car) {
                next()
              } else if (to.query.category && !to.query.car) {
                next({name: 'cars', query: {category: to.query.category}})
              } else {
                next({name: 'categories'})
              }
            }
          },
          {
            path: ':id',
            name: 'product-id',
            component: () => import('@/pages/products/single/index.vue'),
          }
        ]
      },
      {
        path: "index-fashion-two",
        name: "fashiontwo",
        component: () => import('@/pages/index/index-fashion-two'),
      },
      {
        path: "index-fashion-three",
        name: "fashionthree",
        component: () => import('@/pages/index/index-fashion-three'),
      },
      {
        path: "index-fashion-four",
        name: "fashionfour",
        component: () => import('@/pages/index/index-fashion-four'),
      },
      {
        path: "shop-grid",
        name: "Shop Grid",
        component: () => import('@/pages/shop/shop-grid/shop-grid.vue'),
      },
      {
        path: "shop-grid-left-sidebar",
        name: "SHOP GRID",
        component: () => import('@/pages/shop/shop-grid/shop-grid-left-sidebar.vue'),
      },
      {
        path: "shop-grid-right-sidebar",
        name: "Shop grid",
        component: () => import('@/pages/shop/shop-grid/shop-grid-right-sidebar.vue'),
      },
      {
        path: "shop-list",
        name: "Shop list",
        component: () => import('@/pages/shop/shop-list/shop-list.vue'),
      },
      {
        path: "shop-list-left-sidebar",
        name: "Shop List",
        component: () => import('@/pages/shop/shop-list/shop-list-left-sidebar.vue'),
      },
      {
        path: "shop-list-right-sidebar",
        name: "Shop Right",
        component: () => import('@/pages/shop/shop-list/shop-list-right-sidebar.vue'),
      },
      {
        path: "product-detail-one",
        name: "product-one",
        component: () => import('@/pages/shop/shop-detail/product-detail-one.vue'),
      },
      {
        path: "product-detail-two",
        name: "Procuts",
        component: () => import('@/pages/shop/shop-detail/product-detail-two.vue'),
      },
      {
        path: "product-detail-three",
        name: "ProcutS",
        component: () => import('@/pages/shop/shop-detail/product-detail-three.vue'),
      },
      {
        path: "our-store",
        name: "our store",
        component: () => import('@/pages/shop/our-store.vue'),
      },
      {
        path: "brands",
        name: "brands",
        component: () => import('@/pages/shop/brands.vue'),
      },
      {
        path: "sale",
        name: "sale",
        component: () => import('@/pages/sale.vue'),
      },
      {
        path: "aboutus",
        name: "aboutus",
        component: () => import('@/pages/aboutus.vue'),
      },
      {
        path: "contact",
        name: "contact",
        component: () => import('@/pages/contact.vue'),
      },
      {
        path: "career",
        name: "career",
        component: () => import('@/pages/career.vue'),
      },
      {
        path: "blogs",
        name: "blog",
        component: () => import('@/pages/blog/blogs.vue'),
      },
      {
        path: "blog-detail",
        name: "blogs",
        component: () => import('@/pages/blog/blog-detail.vue'),
      },
      {
        path: "helpcenter",
        name: "helpcenter",
        component: () => import('@/pages/helpcenter/helpcenter.vue'),
      },
      {
        path: "helpcenter-faqs",
        name: "helpcenter-faqs",
        component: () => import('@/pages/helpcenter/helpcenter-faqs.vue'),
      },
      {
        path: "helpcenter-guides",
        name: "helpcenter-guides",
        component: () => import('@/pages/helpcenter/helpcenter-guides.vue'),
      },
      {
        path: "helpcenter-support",
        name: "helpcenter-support",
        component: () => import('@/pages/helpcenter/helpcenter-support.vue'),
      },
      {
        path: "terms",
        name: "terms",
        component: () => import('@/pages/utility/terms.vue'),
      },
      {
        path: "privacy",
        name: "privacy",
        component: () => import('@/pages/utility/privacy.vue'),
      },
      {
        path: "comingsoon",
        name: "comingsoon",
        component: () => import('@/pages/specialpages/comingsoon.vue'),
      },
      {
        path: "maintenance",
        name: "maintenance",
        component: () => import('@/pages/specialpages/maintenance.vue'),
      },
      {
        path: ":catchAll(.*)",
        name: "Error",
        component: () => import('@/pages/specialpages/error.vue'),
      },
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: () => import('@/pages/login.vue'),
  },
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  /** Navigate to next if middleware is not applied */
  if (!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware;
  const gate = to.meta.gate;
  const context = {to, from, next, gate}
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
});

export default router;