import {useApi} from '@/services/useApi';
import {defineStore} from 'pinia';
import packageJson from '../../package.json';
/*import {useUserStore} from '@/stores/user.js';

const userStore = useUserStore();*/
export const client = {
  appName: 'DKOffroad',
  platform: 'Web',
  market: 'Direct',
  language: 'EN',
  version: packageJson.version
};

export const useCarStore = defineStore('car', {
  state: () => ({
    cars: []
  }),
  getters: {
    get_cars(state) {
      return state.cars;
    }
  },
  actions: {
    async action_get_cars() {
      const request = {
        'request': 11020,
        'client': client
      };
      await useApi(
        request,
        (response) => {
          //console.log("Success: ", response);
          this.set_cars(response.data.cars);
        },
        (errorMessage) => {
          console.log("Handle Credential Expired: ", errorMessage);
          // userStore.resetUser(errorMessage);
        },
        (errorMessage) => {
          console.log("Handle Failure: ", errorMessage);
          // userStore.setErrorMessage(errorMessage);
        }
      );
    },
    async action_get_cars_by_category(payload) {
      const request = {
        'request': 11019,
        'category': {
          id: payload.id
        },
        'client': client
      };
      console.log(request)
      await useApi(
        request,
        (response) => {
          console.log("action_get_cars_by_category: ", response);
          this.set_cars(response.data.cars);
        },
        (errorMessage) => {
          console.log("Handle Credential Expired: ", errorMessage);
          // userStore.resetUser(errorMessage);
        },
        (errorMessage) => {
          console.log("Handle Failure: ", errorMessage);
          // userStore.setErrorMessage(errorMessage);
        }
      );
    },
    set_cars(payload) {
      this.cars = payload;
    }
  },
  persist: {
    pick: []
  }
});


