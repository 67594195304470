<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import feather from 'feather-icons'
import {onMounted} from "vue";
import {useUserStore} from "@/stores/user";
import {useConfigStore} from '@/stores/config';
import {useCarStore} from "@/stores/car";

export default {
  name: 'App',
  components: {},
  mounted() {
    feather.replace()
    this.scrollToTop()

  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  },
  setup() {
    const userStore = useUserStore()
    const configStore = useConfigStore();
    const carStore = useCarStore();

    onMounted(async () => {
      await getBasicProfile();
      await get_configs();
      await get_cars();
    })

    async function getBasicProfile() {
      if (userStore.getUserId !== 0) {
        await userStore.action_getBasicProfile();
      }
    }

    async function get_configs() {
      await configStore.action_get_configs();
    }

    async function get_cars() {
      await carStore.action_get_cars()
      console.log(carStore.get_cars)
    }

  }
}
</script>

<style></style>
