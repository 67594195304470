import {useApi} from '@/services/useApi';
import {defineStore} from 'pinia';
import packageJson from '../../package.json';

export const client = {
  appName: 'DKOffroad',
  platform: 'Web',
  market: 'Direct',
  language: 'EN',
  version: packageJson.version
};

export const useUserStore = defineStore('user', {
  state: () => ({
    username: '',
    password: '',
    credential: '',
    id: 0,
    errorMessage: ''
  }),
  getters: {
    getCredential(state) {
      return state.credential
    },
    getUserId(state) {
      return state.id;
    },
    getUsername(state) {
      return state.username;
    },
    getErrorMessage(state) {
      return state.errorMessage;
    }
  },
  actions: {
    async login(payload) {
      const request = {
        'request': 1008,
        'account': {
          'username': payload.username,
          'password': payload.password
        },
        'client': client
      };
      console.log(request);
      return await useApi(
        request,
        (response) => {
          console.log('Success: ', response);
          const account = response.data.account;
          this.setUserId(account.id)
          this.setUsername(account.username);
          this.setCredential(account.credential);
          return true
        },
        (errorMessage) => {
          this.resetUser(errorMessage);
          console.log('CredentialExpired: ', errorMessage);
          return false
        },
        (errorMessage) => {
          this.setErrorMessage(errorMessage)
          console.log('Failure: ', errorMessage);
          return false
        }
      );
    },
    async action_getBasicProfile() {
      const request = {
        "request": 1009,
        "subRequest": 0,
        "account": {
          "id": this.id,
          "credential": this.credential
        },
        "client": client
      };
      return await useApi(
        request,
        (response) => {
          //console.log('Success: ', response);
          const account = response.data.account;
          this.setUsername(account.username);
          return true
        },
        (errorMessage) => {
          this.resetUser(errorMessage);
          //console.log('CredentialExpired: ', errorMessage);
          return false
        },
        (errorMessage) => {
          //this.setErrorMessage(errorMessage)
          console.log('Failure: ', errorMessage);
          return false
        }
      );
    },
    setErrorMessage(payload) {
      this.errorMessage = payload;
    },
    setCredential(payload) {
      this.credential = payload;
    },
    setUsername(payload) {
      this.username = payload;
    },
    setUserId(payload) {
      this.id = payload;
    },
    resetUser(errorMessage = '') {
      this.setCredential('')
      this.setUserId(0)
      this.setUsername('');
      this.setErrorMessage(errorMessage)
    }
  },
  persist: {
    pick: ['credential', 'id', 'username']
  }
});


